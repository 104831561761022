$main-color: steelblue;
$menu-background: steelblue;
$body-background: ghostwhite ; /* Sehr helles Grau */
$input-border: #ced4da;
$input-disabled: #e9ecef;

/*Farben für Kalender */
$cal-row-bg: ghostwhite;
$cal-normal-day: #f5f5f5;
$cal-normal-day-highlight: lavender;
$cal-weekend-day: #dcdcdc;
$cal-weekend-day-highlight: darkgrey;
$cal-today: #4682b4;
$cal-cell-border: gainsboro;
$cal-special-day: #cfafee;
$cal-special-day-highlight: plum; 
$cal-remarkable-day: #d8bfc0;
$cal-selected-day: lemonchiffon;
$cal-selected-weekend-day: palegoldenrod;
$cal-birthday: orangered;
$cal-light-blue: lightcyan;
$cal-holiday: #d0ffca;

/* Hintergrundfarbe für Info-Bereich */
$info-background: lavender;

/* Farben für Dashboard */
$critical: orange;

/* Farben für Validierung */
$light-red: #FFE4E1;
$light-green: #F0FFF0;
$light-yellow: #F5DEB3;
$light-blue: lavender;
$light-grey: #F5F5F5;
$very-light-blue: #F0F8FF;
$dark-grey: #A9A9A9;
$dark-green: #228B22; /* Für erfolgreiches Login */
$dark-red: #CD5C5C;
$dark-orange: darkorange;

/* Farben für Tabellen */
$col-row-bg: ghostwhite;
$col-row-bg-highlight: lightsteelblue;

/******************************
    Alle Kalender
*******************************/
#monthcalendar-top, #yearcalendar-top, #yearcalendar-top-admin{
  margin-bottom: 2px;
  display: grid;
  grid-template-rows: 1.3rem 1.2rem;
}   
#yearcal-yearpicker, #monthcal-yearpicker, #settings-yearpicker{
  width: 3rem;
}

#monthcal-monthpicker{
  width: 5.5rem;
}

#yearcal-yearpicker, #monthcal-yearpicker, #monthcal-monthpicker, #settings-yearpicker{
  text-align: center;
  font-size: 1rem;
  border: 0;
  margin: 0;
  vertical-align: super;
  color: $main-color;
  background-color: transparent;
}

.date-frame{
  height: 100%;
  width: 100%;
//   font-size: 1rem;
  position: absolute;
  display: grid;
  grid-template-columns: 15% 35% 35% 15%;
  grid-template-rows: 15% 40% 15% 15% 15%;   
  user-select: none;
  cursor: pointer;
  &.header{
      display: grid;
      grid-template-rows: 15% 85%;  
  }
  &.holiday{
    background-color: $cal-holiday;
  }  
  &.weekend{
    background-color: $cal-weekend-day;
    &.selday{
        background-color: $cal-selected-weekend-day;
    }
  }  
  &.remarkable{
    background-color: $cal-remarkable-day;
}
  &.special{
      background-color: $cal-special-day;
  }
  &.remarkborder{
      background-color: $cal-light-blue;
  }
  &.selday{
      background-color: $cal-selected-day;
  }
  &.retired{
      background: 
           linear-gradient(to top left,
               rgba(219,112,147,0) 0%,
               rgba(219,112,147,0) calc(50% - 0.8px),
               rgba(219,112,147,1) 50%,
               rgba(219,112,147,0) calc(50% + 0.8px),
               rgba(219,112,147,0) 100%),
           linear-gradient(to top right,
               rgba(219,112,147,0) 0%,
               rgba(219,112,147,0) calc(50% - 0.8px),
               rgba(219,112,147,1) 50%,
               rgba(219,112,147,0) calc(50% + 0.8px),
               rgba(219,112,147,0) 100%);    
  }    
  &.today{
      background-color: $cal-today;
      color: white;
  }
  &.todayborder{
      background-color: $light-blue;
  }    
  &.selected{
      background-color: $cal-normal-day-highlight;
      border-top: 1px solid $col-row-bg-highlight;
      border-bottom: 1px solid $col-row-bg-highlight;
      &.first-selected{
        border-left: 1px solid $col-row-bg-highlight;
      }
      &.last-selected{
        border-right: 1px solid $col-row-bg-highlight;
      }
      &.selday{
          background-color: $cal-selected-day;
      }                  
      &.weekend{
          background-color: $cal-weekend-day-highlight;
          &.selday{
              background-color: $cal-selected-weekend-day;
          }                    
      }
      &.offday{
          background-color: $cal-special-day-highlight;
      }       
      &.remarkborder{
          background-color: $cal-remarkable-day;
      }
  }        
}
.day-container{
    position: relative;
    // height: 1.95rem;
    background-color: $cal-normal-day;
}

.date-frame-half{
    grid-row: 1 / 6;
    grid-column: 3 / 6;
}

.date-overlay{
    width: 100%;
    height: 100%;
    background-color: yellow;
    opacity: 0.2;
    position: absolute;
    z-index: 1000;
}

.highlight-approvement{
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.birthday-overlay{
    width: 100%;
    height: 1.95rem;
    opacity: 0.4;
    position: absolute;
    z-index: 100;
}

.date-text{
    grid-row: 1 / 6;
    grid-column: 1 / 5;
    display: flex;
    justify-content: center;
    align-self: center; 
    z-index: 10;
    font-weight: normal;
    font-size: 14px;
}     
.date-arrow{
    grid-row: 2 / -1;
    color: orangered;
    font-size: 1.3rem !important;
    min-width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center; 
    justify-content: center;
    overflow: visible;
}
.date-arrow-left{
  grid-column: 1;
}
.date-arrow-right{
  grid-column: -1;
}   
.remind-arrow{
    grid-row: 1;
    color: orangered;
    grid-column: 2 / -2;
    display: flex;
    align-items: center; 
    justify-content: center;
} 
.remind-bar-prev{
    grid-row: 1;
    grid-column: 1 / 3;
    border-top: 2px solid orangered;
}
.remind-bar-next{
    grid-row: 1;
    grid-column: 3 / -1;
    border-top: 2px solid orangered;
}
.date-marker-1{
    grid-row: 3 / 4;
    border-top: 1px solid white;
    &.full{
        grid-column: 1 / 5; 
    }
    &.left{
        grid-column: 1 / 3;
    }
    &.right{
        grid-column: 3 / 5;
    }
}    
.date-marker-2{
    grid-row: 4 / 5;
    border-top: 1px solid white;
    &.full{
        grid-column: 1 / 5; 
    }
    &.left{
        grid-column: 1 / 3;
    }
    &.right{
        grid-column: 3 / 5;
    } 
}    
.date-marker-3{
    grid-row: 5;
    border-top: 1px solid white;
    &.full{
        grid-column: 1 / 5; 
    }
    &.left{
        grid-column: 1 / 3;
    }
    &.right{
        grid-column: 3 / 5;
    }    
}
.unpublished{
  background-image: linear-gradient(90deg, transparent 50%, rgba(255,255,255,1) 50%);
  background-size: 4px 2px;
}
.birthday-marker{
  grid-row: 1;
  grid-column: 4;
  color: $cal-birthday;   
  width: 6px;
  height: 6px;
  display: flex;
  justify-self: center;
  z-index: 100;
}

.legend{
  margin-top: 4px;
  height: 1.2rem;
  font-size: 0.8rem;
  border: 1px solid $light-grey;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  & .legend-centered{
      grid-column: 2;
      display: grid;
      grid-template-columns: 1rem 8rem 1rem 8rem 1rem 8rem;
      grid-column-gap: 2px;
      background-color: $light-grey;
      border: 1px solid $dark-grey;
      & .legend-square{
          width: 0.8rem;
          height: 0.8rem;
          border: 1px solid white;
          display: flex;
          align-self: center;
          justify-self: center;
          &#weekend-square{
              background-color: $cal-weekend-day;
          }
          &#today-square{
              background-color: $cal-today;
          }
          &#holiday-square{
              background-color: $cal-holiday;
          }
      }
      & .legend-text{
          display: flex;
          align-self: center;         
      }
  }
}

.info-line{
  margin-top: 4px;
  height: 1.2rem;
  font-size: 12px;
  padding-top: 2px;
  background-color: $info-background;
  color: $main-color;   
  display: grid;
  grid-template-columns: 4px 25% 65% 1fr 4px;
}
#sel-person-info{
  grid-row: 1;
  grid-column: 2;
  font-weight: bold;
}

#selection-info{
  grid-row: 1;
  grid-column: 3 / 4;
  font-weight: bold;
  text-align: center;
}
.close-selection-button{
  color: $dark-grey;
  border-radius: 7px;
  background-color: $light-grey;
  position: absolute;
  top: -4px;
  left: -4px;
  z-index: 1000;
  cursor: pointer;
}
#countdown-text{
  grid-column: 4;
  display: flex;
  align-self: center;
  justify-self: end;
  min-width: 2rem;
  margin-left: 2px;
}
#vacation-wrapper{
  padding: 4px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1.6rem;
  grid-column-gap: 10px;
}
#vacation-stats{
  background-color: white;
  border: 1px solid $light-blue;
  font-size: 0.8rem;
  padding: 4px 0;
  display: grid;
  grid-template-columns: 1fr 12rem 6rem 1fr;
  grid-template-rows: 1rem 1rem 1rem 2px 1rem 1rem 1rem 1rem 2px 1rem;
  & .vacation-stats-text{
      grid-column: 2;
  }
  & .vacation-stats-value{
      text-align: right;
      grid-column: 3;
  }
  & .row-line{
      border-top: 1px solid black;
      margin-top: 3px;
      grid-column: 2 / 4;
  }
}

#disease-stats{
  background-color: white;
  border: 1px solid $light-blue;
  font-size: 0.8rem;
  padding: 2px 0;
  grid-column: 1;
  grid-row: 2;
  display: grid;
  grid-template-columns: 1fr 12rem 6rem 1fr;
  grid-template-rows: 1rem;
  & .disease-stats-text{
      grid-column: 2;
  }
  & .disease-stats-value{
      text-align: right;
      grid-column: 3;
  }  
}

#birthdayinfo{
  background-color: white;
  border: 1px solid $light-blue;    
  padding: 4px;
  grid-column: 2;
  font-size: 1rem;
  color: $cal-birthday;
  grid-row: 1;
}

#selectionbuttons{
  grid-column: 3 / 5;
  grid-row: 1 / 3;
  background-color: white;
  border: 1px solid $light-blue;    
  padding: 4px;    
  position: relative;
}

.selinfo-wrapper{
  margin-bottom: 2px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 5px 1fr 5px;
  & .selinfo-arrow-left{
      grid-column: 1;
      grid-row: 1;
      color: orangered;
      font-size: 1.3rem;
      max-width: 100%;
      align-self: center; 
  }
  & .selinfo-arrow-right{
      grid-column: 3;
      grid-row: 1;
      color: orangered;     
      font-size: 1.3rem;
      max-width: 100%;
      align-self: center; 
  }    
  & .selinfo{
      grid-row: 1;
      grid-column: 2;
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 2fr 1fr 4rem 4rem 4rem 4rem;
      grid-column-gap: 4px;
      padding: 0 2px;
  }
  & .selinfo-dates{
      font-size: 0.8rem;
      grid-row: 1;
      grid-column: 1;
  }
  & .selinfo-type{
      font-size: 0.8rem;
      grid-row: 2;
      grid-column: 1;
  }
  & .selinfo-days{
      grid-row: 1 / 3;
      grid-column: 2;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;         
  }
  & .btn-accept{
      grid-row: 1 / 3;
      grid-column: 3;
  }
  & .btn-decline{
      grid-row: 1 / 3;
      grid-column: 4;
  }
  & .btn-delete{
      grid-row: 1 / 3;
      grid-column: 5;
  }
  & .btn-edit{
      grid-row: 1 / 3;
      grid-column: 6;
  }
  & .btn-publish{
      grid-row: 1 / 3;
      grid-column: 4;
  }
}

.btn-cal-selection{
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;      
  align-self: center;
  border: none;
  border-radius: 0.125rem;
  &:hover,
  &:active,
  &:focus {
    box-shadow: 0.1rem 0.1rem 0.5rem rgba(0,0,0,0.3);
    outline: 0;
  }       
}

.progressbox{
    padding: 4px !important;
}

/******************************
  Monatskalender
*******************************/
#monthcalendar-top{
  grid-template-columns: 10.5rem 8.5rem 2.5rem 18rem 1fr;
  & #month-wrapper{
      grid-column: 1;
      grid-row: 1 / 3;
      display: flex;
      align-items: center;         
  }
  & #year-wrapper{
      grid-column: 2;
      grid-row: 1 / 3;
      display: flex;
      align-items: center;        
  }
  & #today-wrapper{
      grid-column: 3;
      grid-row: 1 / 3;
      display: flex;
      align-items: center;         
  } 
  & #department-wrapper{
      grid-column: 4;
      grid-row: 1 / 3;
      display: flex;
      align-items: center;         
  }    
}

#monthcal{
  & .header-row, .footer-row{
      display: grid;
      grid-template-columns: 15rem repeat(31, 1fr) 6px 17px;
      border-bottom: 1px solid $main-color;    
      font-size: 0.8rem;
      & .day-frame, & .personname{
          display: flex;
          justify-content: center;
          align-items: center;            
      }       
  }
  & .calendar-row.selected{
      background-color: $col-row-bg-highlight;
      & .monthname, & .personname{
          border-right: 1px solid $col-row-bg-highlight;
          font-weight: bold;
      }            
      & .date-frame{
          background-color: $cal-normal-day-highlight;
          border-top: 1px solid $col-row-bg-highlight;
          border-bottom: 1px solid $col-row-bg-highlight;
          &.selday{
              background-color: $cal-selected-day;
          }                  
          &.weekend{
              background-color: $cal-weekend-day-highlight;
              &.selday{
                  background-color: $cal-selected-weekend-day;
              }                    
          }
          &.offday{
              background-color: $cal-special-day-highlight;
          }
          &.todayborder{
              background-color: $cal-today;
          }    
          &.remarkborder{
              background-color: $cal-remarkable-day;
          }            
      }
  }        
}

.monthcal-tooltip{
  display: grid;
  line-height: 1rem;
  grid-template-columns: 2px 1fr 1fr 2px;
  grid-template-rows: repeat(2, 1fr);
  & .dayname{
      grid-column: 2;
      grid-row: 1;
      font-size: 0.6rem;
      
  }
  & .week{
      grid-column: 3;
      grid-row: 1;
      font-size: 0.6rem;
      justify-self: end;
  }
  & .fulldate{
      grid-column: 2 / 4;
      grid-row: 2;
      font-size: 1rem;
      justify-self: center;
  }
  & .specialday{
      grid-column: 2 / 4;
      font-size: 0.8rem;
      justify-self: center;        
  }
  & .user{
      grid-column: 1 / 5;
      width: 100%;
      font-size: 0.8rem;
      background-color: $main-color;
      color: white;
      text-align: center;
  }
  & .daytype{
      grid-column: 1 / 5;
      width: 100%;
      color: white;
      font-size: 0.6rem;
      line-height: 0.8rem;
      font-style: italic;
      background-color: $main-color;
      text-align: center;
  }
}

.monthcal-header-tooltip{
  display: grid;
  line-height: 1rem;
  grid-template-columns: 2px 1fr 1fr 2px;
  grid-template-rows: 1fr 1.1rem;
  & .dayname{
      grid-column: 2;
      grid-row: 1;
      font-size: 0.6rem;
  }
  & .week{
      grid-column: 3;
      grid-row: 1;
      font-size: 0.6rem;
      justify-self: end;
  }
  & .fulldate{
      grid-column: 2 / 4;
      grid-row: 2;
      font-size: 1rem;
      justify-self: center;
  }
  & .specialday{
      grid-column: 2 / 4;
      font-size: 0.8rem;
      justify-self: center;        
  }
}

/******************************
  Jahreskalender
*******************************/
 .yearcalheader {
    margin: -20px -10px 0 -10px;
    height: 54px;
    text-transform: uppercase;
    font-size: 1.5rem;
    padding-top: 0.5rem;
    border-bottom: 1px solid lightgray;
    display: flex;
    align-items: center;
    background-color: #ECECEC;
}
.yearcalheader .icon {
    cursor: pointer;
    transition: .15s ease-out;
}
.yearcalheader .icon:first-of-type {
    margin-left: 0.5rem;
}
#yearcal .header .icon:last-of-type {
    margin-right: 0.5rem;
}
#yearcal .column {
    display: flex;
    height: 2vh;
    max-width: 100%;
    align-items: center;
}
#yearcalendar-top{
  grid-template-columns: 8.5rem 2.5rem 1fr 1fr 5rem;
  font-size: 1rem;    
  & #year-wrapper{
      grid-column: 1;
      grid-row: 1 / 3;
      display: flex;
      align-items: center;        
  }
  & #today-wrapper{
      grid-column: 2;
      grid-row: 1 / 3;
      display: flex;
      align-items: center;         
  }
  & #publish-wrapper{
      grid-column: 5;
      grid-row: 1 / 3;
      display: flex;
      justify-content: flex-end;
      & #btn-publish-all{
          color: white;
          background-color: $critical;
          width: auto;
          margin-right: 4px;
          display: flex;
          align-self: center;
          & #numPublish{
              padding-left: 2px;
          }
      }
  }  
}

#yearcalendar-top-admin{
  grid-template-columns: 10.5rem 8.5rem 2.5rem 10rem 1fr;
  font-size: 1rem;
  & #user-wrapper{
      grid-column: 1;
      grid-row: 1 / 3;    
      & #user-select{
          width: 95%;
      }
  }    
  & #year-wrapper{
      grid-column: 2;
      grid-row: 1 / 3;
      display: flex;
      align-items: center;        
  }
  & #today-wrapper{
      grid-column: 3;
      grid-row: 1 / 3;
      display: flex;
      align-items: center;         
  }     
}

.todayIcon{
    display: block;
    margin: auto;
    width: 50px;
    height: 50px;
}
.birthdayIcon{
    position: absolute;
    top: 0;
    right: 0;
}

#yearcal{
  & .header-row, .footer-row{
      display: grid;
      grid-template-columns: 10rem repeat(31, 1fr);
      border-bottom: 1px solid $main-color;    
      & .date-frame, & .personname{
          display: flex;
          justify-content: center;
          align-items: center;            
      }        
  }
  & .monthname{
      grid-column: 1;
      border-bottom: none;
      display: flex;
      align-items: center;            
      padding-left: 4px;
      &.selected{
          border-right: 1px solid $col-row-bg-highlight;
          font-weight: bold;
      }            
  }    
  & .calendar-row{
        display: grid;
        grid-template-columns: 8rem 2rem repeat(31, 1fr);        
        &.P{
            border-bottom: 2px groove $cal-weekend-day; 
        }
        &.N{
            border-top: 2px groove $cal-weekend-day; 
        }  
    }   
}
#yearcalstats{
    height: 54px;
    background-color: #ECECEC;
    border-top: 1px solid lightgray;
    padding: 0 8px;
    display: grid;
    grid-template-columns: 50px 1fr;
    align-items: center;     
    justify-content: center;
    & #selecteddays{
        font-size: 1rem;
        grid-column: 1 / 3;
        grid-row: 1;
        display: flex;
        align-items: center;     
        justify-content: center;    
    }
    & #progress{
        grid-column: 1;
        grid-row: 1;
    }
}

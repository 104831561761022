body {
  margin: 0;
  font-size: 16px;
  font-weight: 300;
}
html,
body {
  /* font-size: 1vw !important; */
  height: 100%;
  display: flex;
  flex-direction: column;
}
main {
  background-color: #e0e0e0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
tr,
td {
  cursor: pointer;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.d-none {
  display: none !important;
}
.lineLabel {
  font-size: 16px;
  height: 100%;
  display: flex;
  align-items: center;
}
.backdrop {
  background-color: rgba(0, 0, 0, 0) !important;
}
.selectedItem,
.selectedItem:hover {
  background-color: #243573 !important;
  color: #b0b0b0 !important;
}
.selectedItem > div,
.selectedItem > div:hover {
  color: #b0b0b0 !important;
}
.selectedItem > div > span,
.selectedItem > div > span:hover {
  color: #b0b0b0 !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(220, 220, 220, 1);
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(36, 53, 115, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(36, 53, 115, 0.4);
}
/* MUI-Overrides */
.Mui-focused {
  color: inherit !important;
}
.MuiListItem-gutters {
  padding-left: 24px !important;
}
.MuiPaper-rounded {
  border-radius: 6px !important;
}
.MuiDataGrid-row {
  cursor: pointer;
}
.MuiDataGrid-footerContainer {
  margin-top: 0 !important;
}
/* .MuiDataGrid-columnsContainer {
  min-height: 0 !important;
  line-height: inherit !important;
}
.MuiDataGrid-virtualScroller {
  margin-top: 30px !important;
} */
.MuiButton-root {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  margin-left: 6px !important;
}
.MuiTableRow-root {
  height: 2rem;
}
.MuiTableCell-root {
  padding: 1px 6px !important;
}
.MuiFormControlLabel-root {
  margin-left: 0 !important;
}
.MuiBadge-badge {
  font-size: 0.6rem;
}
.MuiAvatar-root {
  height: 2rem;
  width: 2rem;
  font-size: 1rem !important;
}
.MuiListItemIcon-root {
  width: 4vh !important;
}
.MuiDialog-paper,
.MuiDialogContent-root {
  overflow-y: hidden !important;
}
.MuiFormControlLabel-label.Mui-disabled {
  color: rgb(146, 146, 146) !important;
}
.MuiSwitch-switchBase.Mui-disabled {
  color: rgb(185, 185, 185, 0.5) !important;
}
.MuiSwitch-colorSecondary.Mui-disabled {
  color: rgb(146, 146, 146, 0.5) !important;
}
.Mui-selected {
  color: rgb(0, 176, 255) !important;
}

/* Media-Querys */

@media only screen and (max-width: 1920px) and (max-height: 1080px) {
  .loginFrame {
    margin: auto 5%;
  }
}

/* @media only screen 
   and (max-width : 640px) 
   and (max-height : 3600px) {
     html, body{
      font-size: 6px;
     }
}  */

